<template>
  <v-container pa-0 fluid fill-height style="overflow: hidden">
    <v-layout column>
      <WeveLoading loading v-if="!ready" />
      <template v-else>
        <!-- All Components that should wait for user to be ready should be under here -->
        <MobileMain v-if="mobile" :orientation="orientation" />
        <template v-else>
          <Main :key="`multi-team-${isMultiTeam}`" />
          <HostBottomToolBar v-if="isBottomToolbar" v-show="isToolbarVisible" />
        </template>
      </template>
    </v-layout>
    <VenmoModal />
    <UserAudio
      v-for="user in audio"
      :key="`user-audio-${user.id}`"
      :track="user.audioTrack"
    />
    <ScribeSound v-if="!isViewerHostLike" />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex"

import Mission from "@shared/enums/Mission"
import Mode from "@shared/enums/Mode"
import User from "@shared/User"
import TwilioStreamActionTypes from "@/store/modules/TwilioStream/action-types"

import RaffleConsumer from "@/modules/raffle/core/consumer"

import { db } from "@/firebase"
import { updateGameWith } from "@/services/game.service"
import NavigationService from "@/services/navigation.service"
import UserService from "@/services/user.service"

import UserAudio from "@/components/GroupTeams/Common/User/UserAudio"
import VenmoModal from "@/components/GroupTeams/Common/VenmoModal.vue"
import Main from "@/components/GroupTeams/Common/Main"
import WeveLoading from "@/components/WeveLoading"

import useMissions from "@/use/useMissions"
import useUserAudio from "@/use/useUserAudio"
import { isIRLUser } from "@shared/helpers/isIRL"

import { KEY_ESCAPE } from "keycode-js"

const isKickstartRequired = game =>
  game &&
  game.hostless2 &&
  !game.diy &&
  !(game.autopilot && game.started) &&
  game.startTimestamp < Date.now()

export default RaffleConsumer.extend({
  name: "Room",
  components: {
    WeveLoading,
    Main,
    UserAudio,
    VenmoModal,
    ScribeSound: () => import("@/components/ScribeSound"),
    HostBottomToolBar: () =>
      import("@/components/GroupTeams/Common/HostBottomToolBar"),
    AutoMusicToolBox: () => import("@/components/ToolBox/AutoMusic"),
    MobileMain: () => import("@/components/GroupTeams/Mobile/MobileMain"),
    MeetingRating: () => import("@/components/ToolBox/MeetingRating")
  },
  setup() {
    useMissions()
    const { IRLSpeakers } = useUserAudio()
    return { IRLSpeakers }
  },
  data() {
    return {
      loading: true
    }
  },
  watch: {
    isTeamOrScribeMissing: {
      handler: _.debounce(function (newValue, oldValue) {
        if (newValue === true && !oldValue) {
          this.$store.dispatch("group/autoScribeAndTeam")
        }
      }, 1000),
      immediate: true
    },
    IRLSpeakers: _.debounce(
      function (newValue, oldValue) {
        const viewer = this.user
        if (!User.isHost(viewer) && !User.isPresenter(viewer)) return
        if (!newValue) return
        if (newValue.length < 1) return

        const before = (oldValue ?? []).sort(User.sort)
        const after = newValue.sort(User.sort)

        if (
          before.reduce((acc, val) => acc + val.id, "") ===
          after.reduce((acc, val) => acc + val.id, "")
        )
          return

        if (after.length === 1) {
          this.$toast(`${User.getShortenedName(after[0])} is the IRL speaker.`)

          return
        }

        this.$toast(
          `${after.reduce(
            (acc, val) => acc + User.getShortenedName(val) + ", ",
            ""
          )} are IRL speakers.`
        )
      },
      1000,
      { leading: true }
    ),
    version: {
      handler(newValue, oldValue) {
        const before = parseInt(oldValue) || 0
        const after = parseInt(newValue) || 0
        if (after > before && this.isHost) {
          const message = `
            <div style="font-size: 20px; text-align: center;">
              ✨ The game content has been updated ✨
            </div>
          `
          this.$info(message)
        }
      }
    },
    roomId: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && newValue)
          this.$navigator.navigateTo(`/game/${this.$store.getters.urlID}`)
      },
      deep: true
    },
    isUserMuted(value) {
      if (this.isHost) {
        if (this.$store.getters["ScreenCapture/streaming"]) {
          const [track] =
            this.$store.state.ScreenCapture?.mic?.getTracks() || []
          if (track) {
            track.enabled = !value
          }
        }
      }
    },
    isToolbarVisible(value) {
      if (!value) {
        this.$snackbar({
          message: "Press escape to have toolbar return",
          offset: 65
        })
      }
    },
    gameNotification(newValue, prevValue) {
      const { message, notificationBy, notificationTo, timestamp } = newValue
      const { id: userID } = this.user
      if (
        timestamp !== prevValue?.timestamp &&
        timestamp > Date.now() - 10 * 1000 &&
        userID !== notificationBy &&
        (!notificationTo || notificationTo === userID)
      ) {
        this.$toast(message, { autoHideDelay: 5000 })
      }
    }
  },
  created() {
    UserService.keepAlive(this.$store.state.auth.user, this.$route.path)

    const unwatch = this.$watch(
      "authorized",
      async value => {
        if (value) {
          unwatch && unwatch()
          window.addEventListener("keyup", this.onKeyUp)

          // default set of async functions to resolve
          const isRoomStartTime = () => Date.now() > this.gameStartTime

          const promises = [this.subscribeToMissionVideoState()]

          const { isHost, game } = this

          const gameID = game?.theKey

          if (!gameID) throw new Error("Game ID is missing!")

          if (
            ((game.moderated && game.diy) || this.client.demo) &&
            !game.notificationStatus
          ) {
            await db
              .auxiliary()
              .ref(`org/${this.orgID}/games/${gameID}/notificationStatus`)
              .set("sent")
          }

          // for host only
          if (isHost) {
            // update game status to started only when it's actual start time
            if (
              !this.gameStarted &&
              (!this.hasPreGame || (this.hasPreGame && isRoomStartTime()))
            ) {
              promises.push(
                this.updateGameAny({ theKey: gameID, started: true })
              )
            }
          } else if (User.isPlayer(this.user) && !game.allowReentry) {
            await this.pushRoomIdToUserSessionHistory({
              clientID: game.clientID,
              userID: this.user.id,
              // GameID is original id (if it's a copy of game or game.id)
              gameID: game.originalGameID || gameID
            })
          }

          await Promise.all(promises)

          if (isKickstartRequired(game) && !this.isHost) {
            console.log("Trying to kickstart...")
            try {
              await NavigationService.start(this.orgID, gameID)
              console.log("Kickstart success.")
            } catch (e) {
              console.error(e)
            }
          }

          this.loading = false
        }
      },
      { immediate: true }
    )
  },
  beforeDestroy() {
    try {
      this.stopTwilioStream()
    } catch (e) {
      console.error(e)
    }

    if (this.streaming) {
      try {
        this.stopStreaming()
      } catch (e) {
        console.error(e)
      }
    }

    window.removeEventListener("keyup", this.onKeyUp)

    document.documentElement.style.removeProperty("overflow")
  },
  computed: {
    ...mapGetters("group", ["isMultiTeam"]),
    ...mapGetters({
      mission: "getCurrentMission",
      missionID: "currentMission"
    }),
    ...mapGetters("twilio", { tracks: "users" }),
    ...mapGetters("auth", [
      "user",
      "client",
      "hasPreGame",
      "isHost",
      "isModerator",
      "isAudit",
      "isSpectator",
      "status",
      "authorized"
    ]),
    ...mapGetters([
      "missions",
      "onlineUsersArray",
      "gameID",
      "gameStatus",
      "chats",
      "game",
      "getCurrentMode",
      "orgID",
      "game",
      "moderatorID"
    ]),
    ...mapGetters("ScreenCapture", ["streaming"]),
    ...mapGetters("Mobile", ["orientation", "mobile"]),
    roomId() {
      return this.game?.theKey // jeez
    },
    version() {
      return this.game?.version
    },
    isViewerHostLike() {
      return this.$store.getters["group/isViewerHostLike"]
    },
    isUserMuted() {
      return this.user?.muted === true
    },
    isHostHidden() {
      return !!this.game?.isHostHidden
    },
    gameStarted() {
      return this.game && this.user.gameID ? !!this.game.started : false
    },
    gameStartTime() {
      return this.game && this.user.gameID ? this.game.startTimestamp : 0
    },
    behavior() {
      return this.mission ? this.mission.behavior : null
    },
    gameOverTeamID() {
      return this.gameStatus.flippedTeamID
    },
    mode() {
      return this.getCurrentMode
    },
    isFactMatchResult() {
      return this.isFactMatchBehavior && this.mode === Mode.Results
    },
    isGameOver() {
      return this.mode === Mode.Over
    },
    isFactMatch() {
      return (
        (this.mode === Mode.Social || this.mode === Mode.Huddle) &&
        this.isFactMatchBehavior
      )
    },
    isFactMatchBehavior() {
      return [
        Mission.MatchGame,
        Mission.FactMatch,
        Mission.OrderTheCards
      ].includes(this.behavior)
    },
    isMeetingMode() {
      return this.getCurrentMode === Mode.Meeting
    },
    predicates() {
      const isHybridRoom = this.$store.getters["auth/isHybridRoom"]
      const viewer = this.$store.getters["auth/user"]
      const pinnedTeamID = this.game?.pinnedTeamID
      const isPinnedUser = user => user.teamID === pinnedTeamID
      const isUserAutoMuted =
        this.$store.getters["group/isUserMutedByRoomLogic"]
      const isHostLike = this.$store.getters["group/isUserHostLike"]
      const IRLSpeakers = this.IRLSpeakers
      const isUserIRLSpeaker = user =>
        IRLSpeakers.some(({ id }) => user?.id === id)

      const predicates = [
        user => user.id !== viewer.id,
        user => !User.isMuted(user)
      ]

      if (isHybridRoom) {
        if (User.isPresenter(viewer)) {
          predicates.push(
            user => !isIRLUser(user, this.game) || isUserIRLSpeaker(user)
          )
        } else if (
          isHostLike(viewer) &&
          isIRLUser(viewer, this.game) &&
          !viewer.monitoring
        ) {
          predicates.push(user => !isIRLUser(user, this.game))
        } else {
          predicates.push(
            user => !isIRLUser(user, this.game) || isUserIRLSpeaker(user)
          )
        }
      }

      if (pinnedTeamID) {
        predicates.push(user => isPinnedUser(user) || isHostLike(user))
      } else if (this.isFactMatchResult) {
        const array = this.otherTeamFactUserIDs(this.gameOverTeamID)
        predicates.push(
          user =>
            isHostLike(user) ||
            User.isPresenter(user) ||
            array.includes(user?.id)
        )
      } else if (this.isGameOver) {
        const teamID = this.gameOverTeamID
        predicates.push(
          user =>
            isHostLike(user) || User.isPresenter(user) || teamID === user.teamID
        )
      } else if (isHybridRoom) {
        predicates.push(
          user => !isUserAutoMuted(user) || isUserIRLSpeaker(user)
        )
      } else {
        predicates.push(user => !isUserAutoMuted(user))
      }

      return predicates
    },
    audio() {
      const isHybridRoom = this.$store.getters["auth/isHybridRoom"]

      const viewer = this.$store.getters["auth/user"]

      if (isHybridRoom) {
        // no mobile users can make any noise
        if (User.isMobile(viewer)) return []
        if (User.isObserver(viewer)) return []

        const IRLSpeakers = this.IRLSpeakers
        const isHostLike = this.$store.getters["group/isUserHostLike"]
        const isUserInViewerRoom = user =>
          user?.identifier === viewer?.identifier
        const isUserViewer = user => user?.id === viewer?.id

        if (User.isPresenter(viewer) && this.mode === Mode.Huddle) return []

        if (
          User.isPresenter(viewer) &&
          IRLSpeakers.length > 0 &&
          IRLSpeakers.some(
            user => isUserInViewerRoom(user) && !isUserViewer(user)
          )
        ) {
          return []
        }

        if (
          isHostLike(viewer) &&
          isIRLUser(viewer, this.game) &&
          !this.$store.getters["auth/viewerHasHeadphones"]
        )
          return []
      }

      const tracks = this.tracks || {}

      const audio = this.onlineUsersArray.reduce((acc, user) => {
        if (this.predicates.some(predicate => !predicate(user))) return acc
        const audioTrack = tracks[user.id]?.audioTrack

        if (!audioTrack) return acc
        acc.push({ id: user.id, audioTrack })
        return acc
      }, [])

      return audio
    },
    isToolbarVisible() {
      return !this.game?.isToolbarHidden
    },
    isBottomToolbar() {
      return !(this.isHostHidden && this.isMeetingMode) && this.isHost
    },
    gameNotification() {
      return this.gameStatus?.notification
    },
    ready() {
      return (
        this.authorized && Object.keys(this.chats).length >= 0 && !this.loading
      )
    },
    isTeamOrScribeMissing() {
      const viewer = this.user

      if (!viewer) return false

      const isViewerMissingTeam = () => {
        return (
          !viewer.teamID ||
          viewer.teamID === 0 ||
          viewer.teamID === "null" ||
          viewer.teamID === "undefined" ||
          !this.chats?.[viewer.teamID]
        )
      }

      const isViewerTeamMissingScribe = () => {
        return this.onlineUsersArray
          .filter(user => user.teamID === viewer.teamID)
          .every(user => !User.isScribe(user))
      }

      const canViwereRequestAutoTeamAndScribe = () => {
        return (
          User.isPlayer(viewer) &&
          !this.$store.getters["group/isUserHostLike"](viewer)
        )
      }

      return (
        canViwereRequestAutoTeamAndScribe() &&
        (isViewerMissingTeam() || isViewerTeamMissingScribe())
      )
    }
  },
  mounted() {
    document.documentElement.style.setProperty("overflow", "hidden")
  },
  methods: {
    ...mapActions("TwilioStream", {
      stopTwilioStream: TwilioStreamActionTypes.STOP_STREAM
    }),
    ...mapActions("group", ["subscribeToMissionVideoState"]),
    ...mapActions("Games", ["updateGameAny"]),
    ...mapActions("pregame", ["pushRoomIdToUserSessionHistory"]),
    ...mapActions("ScreenCapture", ["stopStreaming"]),
    onKeyUp(e) {
      if (e.keyCode === KEY_ESCAPE && this.game?.isToolbarHidden)
        return this.toggleToolbarVisibility()
    },
    toggleToolbarVisibility() {
      updateGameWith(this.gameID, this.orgID, {
        isToolbarHidden: !this.game?.isToolbarHidden
      })
    },
    otherTeamFactUserIDs(teamID) {
      if (!teamID) return []
      const chats = this.chats
      if (!chats) return []
      if (this.behavior !== Mission.FactMatch) return []
      const team = chats[teamID]
      if (!team) return []
      const { otherTeamFacts } = chats[teamID]
      if (!Array.isArray(otherTeamFacts)) return []
      return otherTeamFacts.map(({ fact }) => fact.userID)
    }
  }
})
</script>

<style lang="scss">
.rtb-button {
  background-color: currentColor !important;
  color: $primary_accent_color !important;
  height: 25px;
}
.theme--light.v-label {
  color: #fff;
}
.rtb-select.v-select {
  input {
    padding: 0 0 0 8px;
  }
  .v-label {
    padding: 0 20px 0 8px;
    max-width: 100%;
    box-sizing: border-box;
  }
  .v-input__control {
    min-height: unset;
  }
  .v-input__slot {
    min-height: unset;
    box-sizing: border-box;
    padding: 0;
    .v-select__slot {
      height: 25px;
      .v-select__selection {
        margin-top: 0;
      }
    }
  }
}
.v-text-field {
  margin: 0;
  padding: 0;
}

.audio-element {
  pointer-events: none;
  position: fixed;
  bottom: 0;
  right: 0;
}
.v-list__tile__title {
  font-size: 13px;
}
</style>
