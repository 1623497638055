import { computed, watch } from "@vue/composition-api"

import User from "@shared/User"

import useStore from "./useStore"
import Mission from "@shared/enums/Mission"

const { store } = useStore()

const game = computed(() => store.getters.game)
const mission = computed(() => store.getters.getCurrentMission)
const users = computed(() => store.getters.onlineUsersArray)
const isHybridRoom = computed(() => store.getters["auth/isHybridRoom"])
const everyoneCanHearHostOnly = computed(
  () => store.getters.everyoneCanHearOnlyHost
)
const hasHybridLocations = computed(
  () => store.getters["group/hasHybridLocations"]
)

function isUserIRLSpeakerCompatible(user) {
  return User.isMobile(user) || User.isObserver(user) || User.isPresenter(user)
}

function isUserPresenter(user) {
  return User.isPresenter(user)
}

const IRLSpeakers = computed(() => {
  if (!isHybridRoom.value) return []
  if (!Array.isArray(users.value)) return []
  if (everyoneCanHearHostOnly.value) return []

  const pinnedTeamID = game.value?.pinnedTeamID
  const speakers = game.value?.speakers || {}

  function isUserSpeaker(user) {
    return speakers[user?.id] != null
  }

  function isUserPinned(user) {
    return user?.teamID != null && user.teamID === pinnedTeamID
  }

  const options = users.value.filter(
    user => isUserIRLSpeakerCompatible(user) && !User.isMuted(user)
  )

  const grouped: Record<string, unknown[]> = options.reduce((acc, val) => {
    const location = (hasHybridLocations.value && val.identifier) ?? "default"
    if (acc[location]) {
      acc[location].push(val)
    } else {
      acc[location] = [val]
    }
    return acc
  }, {})

  const predicates = []

  if (mission.value.behavior === Mission.QuestionCards) {
    predicates.push(isUserSpeaker)
    predicates.push(isUserPresenter)
  } else {
    predicates.push(isUserPinned)
    predicates.push(isUserSpeaker)
    predicates.push(isUserPresenter)
  }

  return Object.values(grouped).reduce((acc, options) => {
    for (const predicate of predicates) {
      const speaker = options.find(user => predicate(user))
      if (speaker) {
        acc.push(speaker)
        break
      }
    }
    return acc
  }, [])
})

watch(IRLSpeakers, value => store.commit("SET_IRL_SPEAKERS", value), {
  immediate: true,
  deep: true
})

export default function useUserAudio() {
  return {
    IRLSpeakers
  }
}
