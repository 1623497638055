var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticStyle: { overflow: "hidden" },
      attrs: { "pa-0": "", fluid: "", "fill-height": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          !_vm.ready
            ? _c("WeveLoading", { attrs: { loading: "" } })
            : [
                _vm.mobile
                  ? _c("MobileMain", {
                      attrs: { orientation: _vm.orientation },
                    })
                  : [
                      _c("Main", { key: "multi-team-" + _vm.isMultiTeam }),
                      _vm.isBottomToolbar
                        ? _c("HostBottomToolBar", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isToolbarVisible,
                                expression: "isToolbarVisible",
                              },
                            ],
                          })
                        : _vm._e(),
                    ],
              ],
        ],
        2
      ),
      _c("VenmoModal"),
      _vm._l(_vm.audio, function (user) {
        return _c("UserAudio", {
          key: "user-audio-" + user.id,
          attrs: { track: user.audioTrack },
        })
      }),
      !_vm.isViewerHostLike ? _c("ScribeSound") : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }