<template>
  <div class="venmo-code" v-if="src" @click="onVenmoModalClose()">
    <div class="box">
      <div class="text">Scan venmo code to tip</div>
      <img :src="src" alt="Venmo Code" />
    </div>
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: "VenmoModal",
  data() {
    return {
      src: false
    }
  },
  created() {
    this.$bus.$on("OPEN_VENMO_MODAL", this.onVenmoModalOpenSub)
  },
  beforeDestroy() {
    this.$bus.$off("OPEN_VENMO_MODAL", this.onVenmoModalOpenSub)
  },
  methods: {
    onVenmoModalOpenSub(src) {
      this.src = src
    },
    onVenmoModalClose() {
      this.src = false
    }
  }
})
</script>

<style lang="scss">
.venmo-code {
  position: fixed;
  width: 130vw;
  height: 130vh;
  z-index: 100;
  left: 0;
  top: 0;
  .box {
    position: fixed;
    width: 300px;
    background: $color-primary-dark;
    border: solid 2px $primary_accent_color;
    border-radius: 10px;
    left: 50vw;
    top: 50vh;
    padding: 20px;
    cursor: pointer;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  .text {
    font-weight: 600;
    text-transform: uppercase;
    color: $color-white;
    font-size: 17px;
    margin-bottom: 20px;
  }
  img {
    width: 175px;
  }
}
</style>
