import instance from "./axios.service"
import { db, ServerValue } from "@/firebase"

export default class NavigationService {
  static select(orgID, gameID, action, add) {
    return instance({
      method: "post",
      url: `/game/${gameID}/users/${action}` + (add ? `?add=1` : ``),
      headers: { "x-gogame-org": orgID }
    })
  }
  static deleteSet(orgID, gameID, originID) {
    return instance({
      method: "delete",
      url: `/game/${gameID}/missions/import/${originID}`,
      headers: { "x-gogame-org": orgID }
    })
  }
  static importSet(orgID, gameID, sourceOrgID, sourceGameID) {
    return instance({
      method: "post",
      url: `/game/${gameID}/missions/import`,
      headers: { "x-gogame-org": orgID },
      data: { sourceGameID, sourceOrgID }
    })
  }
  static start(orgID, gameID) {
    return db
      .auxiliary()
      .ref(`/org/${orgID}/games/${gameID}`)
      .transaction(object => {
        const value = object || {}
        if (value.autopilot && value.started) return
        object.started = true
        object.autopilot = true
        return object
      })
  }
  static next(orgID, gameID, experimental) {
    if (experimental) {
      return db
        .auxiliary()
        .ref()
        .update({
          [`org/${orgID}/game/${gameID}/TEMP/next`]: ServerValue.increment(1),
          [`org/${orgID}/game/${gameID}/TEMP/version`]:
            ServerValue.increment(1),
          [`org/${orgID}/game/${gameID}/gameStatus/version`]:
            ServerValue.increment(1)
        })
    } else {
      const headers = { "x-gogame-org": orgID }
      return instance({
        method: "post",
        url: `/game/${gameID}/next`,
        headers
      })
    }
  }
  static deletePlays(orgID, gameID, missionID, setID) {
    return instance({
      method: "delete",
      url: `/game/${gameID}/plays`,
      headers: { "x-gogame-org": orgID }
    })
  }
  static reset(orgID, gameID, missionID, setID) {
    return instance({
      method: "post",
      url: `/game/${gameID}/reset`,
      headers: { "x-gogame-org": orgID },
      data: { setID, missionID }
    })
  }
  static navigateTo(orgID, gameID, missionID, mode) {
    const headers = { "x-gogame-org": orgID }
    return instance({
      method: "post",
      url: `/game/${gameID}/next/${missionID}` + (mode ? `/${mode}` : ``),
      headers
    })
  }
}
