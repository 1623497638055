var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.src
    ? _c(
        "div",
        {
          staticClass: "venmo-code",
          on: {
            click: function ($event) {
              return _vm.onVenmoModalClose()
            },
          },
        },
        [
          _c("div", { staticClass: "box" }, [
            _c("div", { staticClass: "text" }, [
              _vm._v("Scan venmo code to tip"),
            ]),
            _c("img", { attrs: { src: _vm.src, alt: "Venmo Code" } }),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }